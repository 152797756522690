@-webkit-keyframes slide {
    from { transform: translateY(-2000px); }
    to { transform: translateY(0); }
}

.logo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 3.5em;
  padding: 0.4em;
  z-index: 100;

  &.inverted {
    background-color: theme-color(green);

    svg {
      fill: theme-color(black);
    }
  }

  svg {
    width: 100%;
    height: auto;
    animation: slide 80s linear infinite;
  }
}



@media screen and (max-width: 768px) {

  @-webkit-keyframes slide {
      from { transform: translateY(-1000px); }
      to { transform: translateY(0); }
  }

  .logo {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 2em;
    padding: 0.2em;
    z-index: 100;
  }

}

.arrow-down {
  position: fixed;
  left: calc(50% + 0.5em);
  bottom: 1em;
  z-index: 100;
  color: theme-color(white);
  cursor: pointer;
  font-size: 1.2em;
  text-align: center;

  p {
    margin-bottom: .1em;
  }
}



@media screen and (max-width: 768px) {

  .arrow-down {
    left: calc(50% - 0.5em);
  }

}

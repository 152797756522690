.Newsletter {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: none;

  &.is-visible {
    display: block;
  }
}

.NewsletterClose {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.NewsLetterModal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  transform: translate(-50%,-50%);
  text-align: center;
  padding: 60px 40px;
  max-width: 600px;

  h2 {
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 32px;
  }
}

.NewsletterContainer {
  display: flex;
  width: 100%;
}

.NewsletterContainerTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;

  .mc-field-group:last-of-type {
    margin-right: 0;
  }

  .mc-field-group {
    width: auto;
    display: flex;
    margin-right: 12px;

    input {
      font-style: italic;
      font-size: 100%;
      border-radius: 100px;
      border: 2px solid black;
      padding: .4em .8em .5em .8em;
      font-family: 'Neue', Arial;
      font-weight: normal;

      &::placeholder {
        color: black;
      }
    }
  }
}

.mc-field-group {
  width: 100%;

  input {
    font-style: italic;
    font-size: 100%;
    border-radius: 100px 0 0 100px;
    border: 2px solid black;
    padding: .4em .8em .5em .8em;
    width: 100%;
    font-family: 'Neue', Arial;
    font-weight: normal;

    &::placeholder {
      color: black;
    }
  }
}

.optionalParent {
  input {
    font-size: 100%;
    border: 2px solid black;
    border-radius: 0 100px 100px 0;
    background: black;
    padding: .4em .8em .5em .8em;
    color: white;
    font-family: 'Neue', Arial;
    font-weight: normal;
  }
}

#mc_embed_signup input.mce_inline_error {
    border-color: black;
}

#mc_embed_signup div.mce_inline_error {
    margin: 8px 0;
    padding: 0;
    text-align: left;
    background: transparent;
    z-index: 1;
    font-weight: normal;
    color: black;
}

.NewsletterPrivacy {
  margin-top: 32px;

  p {
    font-size: 0.7em;
    line-height: 1.3;
    margin: 0;
  }
}



@media screen and (max-width: 768px) {

  .NewsLetterModal {
    position: absolute;
    top: 50%;
    left: 50%;
    background: white;
    transform: translate(-50%,-50%);
    text-align: center;
    width: calc(100% - 40px);
    padding: 60px 12px;
  }

}

nav {
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 1001;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
      margin: 0 0 0 .5em;
    }
  }
}

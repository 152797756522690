.accordion {

  .accordion__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 4.5em;
    color: theme-color(black);

    &:last-of-type {
      margin-bottom: 0;
    }

    .accordion__item-title {
      padding: .5em 1em;
      border-top: 1px solid theme-color(black);
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-family: 'Neue', Arial;
        font-size: 1.2rem;
        line-height: 1.3em;
        transform: translateY(0px);
      }


    }

    .accordion__item-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid theme-color(black);
      padding: .75em 1em;

      &:last-of-type {
        border-bottom: 1px solid theme-color(black);
      }

      button {
        width: 100%;

        &.active {
          h3 {
            &:after {
              content:"\2212";
            }
          }
        }

        h3 {
          transform: translateY(3px);
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:hover {
            font-family: 'PlantinItalic', Georgia;

            &:after {
              font-family: 'Plantin', Georgia;
            }
          }

          &:after {
            content:"\002B";
          }
        }
      }
    }

    .accordion__item-text {
      display: none;

      p {
        margin-bottom: .75em;
      }

      &.is-visible {
        display: block;
        text-align: left;
        padding: .75em 0 .25em 0;
        width: 50%;
      }
    }
  }
}



@media screen and (max-width: 768px) {

  .accordion {

    .accordion__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 3em;

      .accordion__item-title {
        padding: 1em .75em;
        border-top: 1px solid theme-color(white);

        h2 {
          font-size: 1em;
          line-height: 1.2em;
        }
      }

      .accordion__item-content {
        padding: .75em;
      }
    }
  }

}

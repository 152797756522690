.button {
  border-radius: 100px;
  padding: .4em .8em .4em .8em;
  display: inline-block;

  &.white {
    background-color: theme-color(white) !important;
    border: 1px solid theme-color(white) !important;
    color: theme-color(black) !important;

    &:hover {
      background-color: theme-color(black) !important;
      border: 1px solid theme-color(black) !important;
      color: theme-color(white) !important;
    }
  }

  &.black {
    background-color: theme-color(black);
    border: 1px solid theme-color(black);
    color: theme-color(white);

    &:hover {
      background-color: theme-color(white);
      border: 1px solid theme-color(white);
      color: theme-color(black);
    }
  }
}

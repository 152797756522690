.card {
  height: calc(100vh - 9em);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card__inner {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    z-index: 100;
    text-align: center;

    .card__inner-item {
      flex: 1;
      color: theme-color(black);

      p:first-of-type {
        margin-bottom: .75em;
      }

      p:last-of-type {
        margin-bottom: 1.75em;
      }

      ul {

        li {

          &:first-of-type {
            margin-bottom: .5em;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {

  .card {
    height: 100%;

    .card__inner {
      flex-direction: column;
      margin-top: 2em;

      .card__inner-item {
        margin-top: 3em;
        flex: 1;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

}

.white {
  background-color: theme-color(white);
}

main {
  margin-top: 4.5em;
  margin-left: 3.5em;
  margin-bottom: 4.5em;
  background-color: theme-color(white);

  &.no-margin {
    margin: 0;
  }

  .title {
    position: absolute;
    top: 1.7em;
    left: 50%;
    transform: translateX(calc(-50% + 1.75em));
  }

}

.image--1 {
  position: fixed;
  bottom: 15%;
  right: 15%;
  width: 30vw;
  transform: rotate(-5deg);
}

.image--2 {
  position: fixed;
  bottom: -15%;
  right: 10%;
  width: 20vw;
  transform: rotate(5deg);
}

@media screen and (max-width: 768px) {

  main {
    margin-top: 4.25em;
    margin-left: 2em;
    margin-bottom: 4.25em;

    .title {
      position: absolute;
      top: 1.7em;
      left: 2.75em;
      transform: translateX(0);
    }
  }

  .image--1, .image--2 {
    display: none;
  }

}

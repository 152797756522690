.cookies {
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 600px;
  padding: 1.5em;
  z-index: 100;
  background-color: theme-color(white);
  transform: translateY(calc(100% + 20px));
  transition: transform 400ms ease;

  p {
    margin: .5em 0 1em 0;
  }

  a {
    margin-right: .5em;
  }
}

.cookies--show .cookies {
  transform: translateY(0);
}

.cookies--hide .cookies {
  transform: translateY(calc(100% + 20px));
}


@media screen and (max-width: 768px) {

  .cookies {
    width: 300px;
    padding: 1em;
    z-index: 100;
  }

}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 1s ease;
  overflow: scroll;

  &.is-visible {
    transform: translateX(0);
  }

  .menu__links {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 4.5em;
    padding-bottom: 4.5em;
    overflow: scroll;
    z-index: 1000;

    .main__menu {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        border-top: 1px solid theme-color(white);
        text-align: center;
        padding: .75em 0;

        &:last-of-type {
          border-bottom: 1px solid theme-color(white);
        }

        &:hover {
          backdrop-filter: blur(20px);
        }

        a {
          display: block;
          color: theme-color(white);
          font-family: 'Plantin', Georgia;
          font-size: 2.25em;
          font-weight: 300;
          line-height: 1.3em;
          text-decoration: none;
          transform: translateY(6px);

          &:hover {
            font-family: 'PlantinItalic', Georgia;
          }
        }
      }
    }

    .sub__menu {
      list-style: none;
      padding: 0;
      margin-top: 3.5em;
      display: flex;
      justify-content: center;

      li {
        margin: 0 .5em;
        padding: 0;
      }
    }

    .secondary {
      flex-grow: 1;
      margin-top: 3.5em;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      a, p {
        color: theme-color(white);
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    z-index: 1;
  }

  img {
    display: none;
  }
}



@media screen and (max-width: 768px) {

  .menu {

    .menu__links {

      .main__menu {

        li {
          padding: .75em;

          a {
            font-size: 2.25em;
          }
        }
      }

      .sub__menu {
        list-style: none;
        padding: 0;
        margin-top: 2.5em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          margin: 0 .25em .5em .25em;
          padding: 0;
        }
      }

      .secondary {
        margin-top: 2em;
        justify-content: flex-start;
      }
    }

    video {
      display: none;
    }

    img {
      display: block;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }

}

.list {
  color: theme-color(black);

  .list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid theme-color(black);
    padding: .75em 1em;

    h1 {
      font-family: 'Plantin', Georgia;
      font-size: 1.5em;
      font-weight: 300;
      line-height: 1.1em;
      transform: translateY(3px);
    }

    &.column {
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        padding: 0;
        width: 50%;
      }

      h2 {
        margin-bottom: .3em;
        width: 50%;
      }
    }

    .l-group {
      display: flex;
      align-items: center;

      p:first-of-type {
        margin-right: 3em;
      }

      p:last-of-type {
        margin-left: 1em;
      }
    }

    &:last-of-type {
      border-bottom: 1px solid theme-color(black);
    }
  }
}

@media screen and (max-width: 768px) {

  .list {

    .list__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: .75em;

      h1 {
        margin-top: .4em;
        margin-bottom: .2em;
      }

      &.column {

        p {
          margin: 0;
          padding: 0;
          width: 100%;
        }

        h3 {
          margin-bottom: .3em;
          width: 100%;
        }
      }

      .l-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p:first-of-type {
          margin-right: 0;
        }

        p:last-of-type {
          margin-left: 0;
        }
      }

      a {
        margin-top: 1em;
      }
    }
  }

}

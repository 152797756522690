.section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .vertical {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3.5em;
    padding: 0.4em;
    z-index: 50;

    &.green {
      background-color: theme-color(green);
    }

    &.red {
      background-color: theme-color(red);
    }

    &.blue {
      background-color: theme-color(blue);
    }

    &.yellow {
      background-color: theme-color(yellow);
    }

    &.white {
      background-color: theme-color(white);
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 3.5em;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      h1 {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 50;
        transform: translate(-50%, -50%);
        margin-top: .20em;
        color: theme-color(white);
      }

      .image {
        overflow: hidden;
        z-index: 50;
        width: calc(160px * 5);
        height: calc(90px * 5);
        position: relative;

        &.square {
          width: calc(90px * 5);
          height: calc(90px * 5);
        }

        h1 {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 50;
          transform: translate(-50%, -50%);
          margin-top: .20em;
          color: theme-color(white);
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .video {
        overflow: hidden;
        z-index: 50;
        width: calc(160px * 5);
        height: calc(90px * 5);
        position: relative;

        .thumbnail {
          position: absolute;
          top: 0;
          left: 0;
          width: calc(160px * 5);
          height: calc(90px * 5);
          object-fit: cover;
          overflow: hidden;
          visibility: visible;
          cursor: pointer;
          z-index: 1;

          &.is-hidden {
            visibility: hidden;
          }

          .mobile {
            display: none;
          }

          .desktop {
            display: block;
          }

          img,
          video {
            width: 100%;
            height: 100%;
            transform: scale(1.05);
          }

          h1 {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: .20em;
            color: theme-color(white);
            z-index: 2;
          }

          .play-button {
            width: 24px;
            height: 24px;
            padding: 1em;
            border: 1px solid theme-color(white);
            border-radius: 100%;
            position: absolute;
            bottom: 1em;
            left: 1em;
            z-index: 2;

            svg {
              transform: translate(-40%, -50%);
              width: 14px;
              fill: theme-color(white);
            }
          }

          &:hover {
            .play-button {
              border: 1px solid theme-color(white);
              background-color: theme-color(white);

              svg {
                fill: theme-color(black);
              }
            }
          }
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(160px * 5);
        height: calc(90px * 5);
        border: 0;
        background: transparent;
      }

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 1em 0 0 0;
        padding: 0;
        z-index: 50;

        li {
          margin: 0 .25em;
          padding: 0;
        }
      }
    }
  }

  .background__image {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    filter: blur(16px);
    transform: scale(1.25);

    &.no--blur {
      filter: blur(0);
      transform: scale(1);
    }
  }

  .overlay__image {
    position: absolute;
    top: 100px;
    left: 50%;
    height: auto;
    transform: translateX(-50%);
    z-index: 2;
    filter: blur(16px);

    &.no--blur {
      filter: blur(0);
    }
  }
}




@media screen and (max-width: 1024px) {

  .section {

    .wrapper {
      left: 2em;

      .wrapper__inner {

        .image {
          width: calc(160px * 4);
          height: calc(90px * 4);

          .text {
            width: 90%;
          }
        }

        .video {

          .thumbnail {
            width: calc(160px * 4);
            height: calc(90px * 4);

            .text {
              width: 90%;
            }
          }
        }

        iframe {
          width: calc(160px * 4);
          height: calc(90px * 4);
        }
      }
    }
  }

}




@media screen and (max-width: 960px) {

  .section {

    .wrapper {
      left: 2em;

      .wrapper__inner {

        .image {
          width: calc(160px * 3.25);
          height: calc(90px * 3.25);

          .text {
            width: 90%;
          }
        }

        .video {

          .thumbnail {
            width: calc(160px * 3.25);
            height: calc(90px * 3.25);

            .text {
              width: 90%;
            }
          }
        }

        iframe {
          width: calc(160px * 3.25);
          height: calc(90px * 3.25);
        }
      }
    }
  }

}





@media screen and (max-width: 768px) {

  .section {

    .vertical {
      width: 2em;
      padding: 0.2em;
    }

    .wrapper {
      left: 2em;

      .wrapper__inner {

        .image {
          width: calc(160px * 2);
          height: calc(160px * 2);

          &.square {
            width: calc(160px * 2);
            height: calc(160px * 2);
          }

          .text {
            width: 90%;
          }
        }

        .video {
          width: calc(160px * 2);
          height: calc(160px * 2);

          .thumbnail {
            width: calc(160px * 2);
            height: calc(160px * 2);

            .mobile {
              display: block;
            }

            .desktop {
              display: none;
            }

            img,
            video {
              object-fit: cover;
            }

            .text {
              width: 90%;
            }
          }
        }

        iframe {
          width: calc(160px * 2);
          height: calc(160px * 2);
          display: none;

          &.is-display {
            display: block !important;
          }
        }
      }
    }
  }

}